import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {createOptions} from "../../../helpers/utils";
import {useFragment, useMutation} from "react-relay";
import {allTaxonomiesDropdown} from "../../../relay/Fragments";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import FormModal from "../../../components/common/modals/FormModal";
const NewPractitionerLocationTaxonomyModal = ({showModal, onClose, practitionerLocationId, allTaxonomies}) => {
    const mutation = graphql`
    mutation NewPractitionerLocationTaxonomyModalMutation($practitionerLocationId: ID!, $taxonomyId: ID!, $seq: Int!, 
        $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
      practitionerLocation {
          createTaxonomy(input: {practitionerLocationId: $practitionerLocationId, taxonomyId: $taxonomyId, seq: $seq, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
              practitionerLocationTaxonomy {
                  id
                  practitionerLocation {
                      taxonomies {
                          ...PractitionerLocationTaxonomies_Taxonomy
                      }}}}}}`
    const taxonomies = createOptions(useFragment(allTaxonomiesDropdown, allTaxonomies), "id", "applicableVersion.displayName");
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        practitionerLocationId: practitionerLocationId,
        taxonomyId: '',
        seq:'',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    }
    const schema = yup.object({
        taxonomyId: yup.string().required("Taxonomy is required"),
        seq: yup.number().required("Seq is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    })
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.practitionerLocation) {
                    toast.success("Taxonomy for Provider Location created")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"New Taxonomy"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={taxonomies} controlId={"taxonomyId"} label={"Taxonomy"} />
            <HorizontalFormInput controlId={"seq"} label={"Seq"} type={"number"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
export default NewPractitionerLocationTaxonomyModal