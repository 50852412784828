import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {FormProvider, useForm} from "react-hook-form";
import {useUser} from "../../hooks/useUser";
import {toast} from "react-toastify";
import InfoPanel from "../../components/common/repeated-page-panels/InfoPanel";
import {allContractsDropdown} from "../contracts/ContractFragments";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import {createProviderFullName} from "../../helpers/utils";

const PractitionerInfo = ({practitioner, legalContracts}) => {
    const mutation = graphql`
    mutation PractitionerInfoMutation($id: ID!, $legalContractId: ID!) {
        practitioner {
            modify(input: {practitionerId: $id, legalContractId: $legalContractId}) {
                practitioner {
                    ...PractitionerInfo_Practitioner
                }
            }
        }
    }`
    const practitionerInfo = useFragment(graphql`
    fragment PractitionerInfo_Practitioner on Practitioner {
        id
        legalContract {
            id
            name
        }
        applicableVersion {
            firstName
            lastName
            middleInitial
            nickName
            qualification
            nameSuffix
        }
    }`, practitioner)
    const allLegalContracts = useFragment(allContractsDropdown, legalContracts).map((c) => {return {value: c.id, label:c.name}});
    const [readMode, setReadMode] = useState(true);
    const [commitMutation, isInFlight] = useMutation(mutation);
    const methods = useForm({values: {legalContractId: practitionerInfo.legalContract.id}})
    const {hasPermission} = useUser();
    
    const onSubmit = (data) => {
        commitMutation({variables: {
            id: practitionerInfo.id,
                ...data
            }, onCompleted: () => {
                toast.success("Practitioner has been updated");
                setReadMode(true);
            }})
    }
    const handleCancelClick = () => {
        methods.reset();
        setReadMode(true);
    }
    return (
        <FormProvider {...methods}>
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <InfoPanel readMode={readMode}
                           onCancelChangesClick={handleCancelClick}
                           name={createProviderFullName(practitionerInfo.applicableVersion.firstName, practitionerInfo.applicableVersion.lastName, practitionerInfo.applicableVersion.middleInitial, practitionerInfo.applicableVersion.nameSuffix, practitionerInfo.applicableVersion.nickName, practitionerInfo.applicableVersion.qualification)}
                           isInFlight={isInFlight}
                           onUpdateDetailsClick={() => {setReadMode(false)}}
                           showUpdate={hasPermission("Providers.Update")}
                           >
                    <HorizontalFormSelect options={allLegalContracts} 
                                          controlId={"legalContractId"} 
                                          label={"Contract"} 
                                          validation={{required: "Contract is required"}} 
                                          readMode={readMode} />
                </InfoPanel>
            </Form>
        </FormProvider>
    );
};

export default PractitionerInfo;
