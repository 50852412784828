import graphql from "babel-plugin-relay/macro";

export const allIdentifierTypesDropdown = graphql`
    fragment Fragments_AllIdentifierTypes on IdentifierType @relay(plural: true) {
        id,
        display
    }`

export const allIdentifierUseCodesDropdown = graphql`
    fragment Fragments_AllIdentifierUseCodes on IdentifierUseCode @relay(plural:true) {
        id,
        display
    }`

export const allContactPointSystemCodesDropdown = graphql`
    fragment Fragments_AllContactPointSystemCodesDropdown on ContactPointSystemCode @relay(plural: true) {
        id,
        display
    }`

export const allContactPointUseCodesDropdown = graphql`
    fragment Fragments_AllContactPointUseCodesDropdown on ContactPointUseCode @relay(plural: true) {
        id,
        display
    }`

export const allAddressUseCodesDropdown = graphql`
    fragment Fragments_AllAddressUseCodesDropdown on AddressUseCode @relay(plural: true) {
        id,
        display
    }`

export const allAttachmentCategoriesDropdown = graphql`
    fragment Fragments_AllAttachmentCategoriesDropdown on AttachmentCategory @relay(plural:true) {
        id
        name
        displayOrder
    }
`

export const allCountiesDropdown = graphql`
    fragment Fragments_AllCountiesDropdown on County @relay(plural: true) {
        id
        name
        fipsCountyCode
        state
    }`

export const allGendersDropdown = graphql`
    fragment Fragments_AllGendersDropdown on Gender @relay(plural: true) {
        id
        name
        displayOrder
    }`

export const allEthnicitiesDropdown = graphql`
    fragment Fragments_AllEthnicitiesDropdown on Ethnicity @relay(plural: true) {
        id
        name
        displayOrder
    }`

export const allProfessionalDesignationDropdown = graphql`
    fragment Fragments_AllProfessionalDesignationDropdown on ProfessionalDesignation @relay(plural: true) {
        id
        name
        displayOrder
    }`

export const allLanguagesDropdown = graphql`
    fragment Fragments_AllLanguagesDropdown on Language @relay(plural: true) {
        id
        name
        isoCode
        displayOrder
    }`

export const allTaxonomiesDropdown = graphql`
    fragment Fragments_AllTaxonomiesDropdown on Taxonomy @relay(plural: true) {
        id
        applicableVersion {
            displayName
        }
    }`