import React from 'react';
import graphql from "babel-plugin-relay/macro";
import NewModal from "../../../components/common/modals/NewModal";
import {createOptions, yesNoUnknownOptions} from "../../../helpers/utils";
import {useFragment, useMutation} from "react-relay";
import {locationsDropdown} from "../../locations/LocationFragments";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import {contractLineOfService} from "../../contracts/ContractLineOfService/ContractLinesOfServiceFragments";
import HorizontalFormCheck from "../../../components/common/horizontal-form-inputs/HorizontalFormCheck";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const NewPractitionerLocationModal = ({showModal, onClose, allLocations, practitionerId, availableLinesOfService}) => {
    const mutation = graphql`
    mutation NewPractitionerLocationModalMutation($practitionerId: ID!, $locationId: ID!, $lineOfServiceIds: [ID!]!,
    $showInDirectory: Boolean!, $acceptingNewPatients: YesNoUnknown!, $providesTelehealth: YesNoUnknown!, 
        $providesOfficeVisits: YesNoUnknown!, $providesHomeVisits: YesNoUnknown!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        practitionerLocation {
            create(input: {practitionerId: $practitionerId, locationId: $locationId,
                showInDirectory: $showInDirectory, providesTelehealth: $providesTelehealth, acceptingNewPatients: $acceptingNewPatients,
            providesHomeVisits: $providesHomeVisits, providesOfficeVisits: $providesOfficeVisits, effectiveFrom: $effectiveFrom,
            effectiveTo: $effectiveTo, legalContractLineOfServiceIds: $lineOfServiceIds}) {
                practitionerLocation {
                    id
                }
            }
        }
    }`
    const locations = createOptions(useFragment(locationsDropdown, allLocations), "id", "applicableVersion.name");
    const linesOfService = useFragment(contractLineOfService, availableLinesOfService);
    const defaultValues = {
        practitionerId: practitionerId,
        locationId: '',
        lineOfServiceIds: [],
        showInDirectory: true,
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    }
    const schema = yup.object({
        locationId: yup.string().required("Location is required"),
        lineOfServiceIds: yup.array().min(1, "At least one line of service is required"),
        showInDirectory: yup.boolean().required("Show in Directory is required"),
        acceptingNewPatients: yup.string().required("Accepting New Patients is required"),
        providesTelehealth: yup.string().required("Provides Telehealth is required"),
        providesHomeVisits: yup.string().required("Provides Home Visits is required"),
        providesOfficeVisits: yup.string().required("Provides Office Visits is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    return (
        <NewModal showModal={showModal}
                  onClose={onClose}
                  mutation={mutation}
                  methods={methods}
                  title={"New Provider Location"}
                  returnedIdKey={"practitionerLocation.create.practitionerLocation.id"}
                  redirectUrl={"/practitioner-locations/"}
        >
            <HorizontalFormSelect options={locations} controlId={"locationId"} label={"Location"} />
            {linesOfService.map(x => {
                return <HorizontalFormCheck key={x.id} controlId={"lineOfServiceIds"} label={x.lineOfService.name} value={x.id} />
            })}
            <HorizontalFormCheck controlId={"showInDirectory"} label={"Show In Directory?"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"acceptingNewPatients"} label={"Accepting New Patients?"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"providesTelehealth"} label={"Provides Telehealth?"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"providesOfficeVisits"} label={"Provides Office Visits?"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"providesHomeVisits"} label={"Provides Home Visits?"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
            
        </NewModal>
    )
}
export default NewPractitionerLocationModal