import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {createOptions} from "../../../helpers/utils";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import {contractLineOfService} from "../../contracts/ContractLineOfService/ContractLinesOfServiceFragments";
const NewPractitionerLocationLineOfServiceModal = ({showModal, onClose, availableLinesOfService, practitionerLocationId}) => {
    const mutation = graphql`
    mutation NewPractitionerLocationLineOfServiceModalMutation($practitionerLocationId: ID!, $lineOfServiceId: ID!, $effectiveFrom: DateOnly!,
    $effectiveTo: DateOnly!) {
        practitionerLocation {
            createLineOfService(input: {practitionerLocationId: $practitionerLocationId, legalContractLineOfServiceId: $lineOfServiceId,
                effectiveFrom:$effectiveFrom, effectiveTo: $effectiveTo}) {
                practitionerLocationLineOfService {
                    id
                    practitionerLocation {
                        linesOfService {
                            ...PractitionerLocationLineOfService
                        }}}}}}`
    const linesOfServices = createOptions(useFragment(contractLineOfService, availableLinesOfService), "id", "lineOfService.name");
    const [commitMutation, isInFlight] = useMutation(mutation)
    const defaultValues = {
        practitionerLocationId: practitionerLocationId,
        lineOfServiceId: '',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    }
    const schema = yup.object({
        lineOfServiceId: yup.string().required("Line of Service is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    })
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.practitionerLocation) {
                    toast.success("Line of Service for Provider Location created")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"New Line of Service"} 
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={linesOfServices} controlId={"lineOfServiceId"} label={"Line of Service"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
export default NewPractitionerLocationLineOfServiceModal