import React, {useState} from 'react';
import {FormatAddress} from "../../../helpers/utils";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {BooleanCell, LinkCell} from "../../../components/common/advance-table/CustomCells";
import {useTableModals} from "../../../hooks/useTableModals";
import {useUser} from "../../../hooks/useUser";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import NewPractitionerLocationModal from "./NewPractitionerLocationModal";

const cols = [
    {accessor: 'location.applicableVersion.name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { location, id } = rowData.row.original;
            return <LinkCell name={location.applicableVersion.name} to={"/practitioner-locations/" + id} hasAvatar />
        }},
    {accessor: 'addressLine1',
        Header: 'Address',
        Cell: rowData => {
            const {location} = rowData.row.original;
            return <>{FormatAddress(location.addressLine1, location.addressLine2, "",
                location.city, location.state, location.postalCode)}</>
        }},
    {accessor: 'applicableVersion.showInDirectory', Header: "Show In Directory", Cell:BooleanCell},
   
]
const PractitionerLocations = ({practitionerId, locations, allLocations, availableLineOfServices}) => {
    const data = useFragment(graphql`
    fragment PractitionerLocations_Locations on PractitionerLocation @relay(plural: true) {
        id
        location {
            addressLine1
            addressLine2
            city
            state
            postalCode
            applicableVersion {
                name
            }
        }
        applicableVersion {
            showInDirectory
        }
    }`,locations)
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Locations"}
                                         table
                                         showNew={hasPermission("ProviderLocations.Create")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewPractitionerLocationModal allLocations={allLocations} 
                                          practitionerId={practitionerId}
                                          showModal={showNewModal}
                                          onClose={() => {setShowNewModal(false)}}
                                          availableLinesOfService={availableLineOfServices}
            />
        </>
    )
}
export default PractitionerLocations