import React, {useEffect} from 'react';
import {useRevalidator} from "react-router-dom";
import {Card, Spinner} from "react-bootstrap";

const FailedRosterLoad = ({rosterLoad}) => {
    return (
        <Card className={"mb-2"}>
            <Card.Body className={"bg-light"}>
                <Card.Title>Processing of this roster load failed: {rosterLoad.errorMessage}</Card.Title>
                <Card.Text style={{ whiteSpace: 'pre-wrap' }}>{rosterLoad.errorDetails}</Card.Text>
            </Card.Body>
        </Card>
    )
}
export default FailedRosterLoad