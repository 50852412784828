import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import dayjs from "dayjs";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import FormModal from "../../../components/common/modals/FormModal";
import {createOptions} from "../../../helpers/utils";
import {contractLineOfService} from "../../contracts/ContractLineOfService/ContractLinesOfServiceFragments";

const EditPractitionerLocationLineOfServiceModal = ({selected, showModal, onClose, availableLinesOfService}) => {
    const mutation = graphql`
    mutation EditPractitionerLocationLineOfServiceModalMutation($practitionerLocationLineOfServiceId: ID!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        practitionerLocation {
            modifyLineOFService(input: {practitionerLocationLineOfServiceId: $practitionerLocationLineOfServiceId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                practitionerLocationLineOfService {
                    id
                    practitionerLocation {
                        linesOfService {
                            ...PractitionerLocationLineOfService
                        }}}}}}`
    const linesOfServices = createOptions(useFragment(contractLineOfService, availableLinesOfService), "id", "lineOfService.name");
    const [commitMutation, isInFlight] = useMutation(mutation);
    const effectiveFrom = selected?.effectiveFrom ? dayjs(selected.effectiveFrom).toDate() : '';
    const effectiveTo = selected?.effectiveTo ? dayjs(selected.effectiveTo).toDate() : '';
    const defaultValues = {
        practitionerLocationLineOfServiceId: selected.id,
        lineOfServiceId: selected.lineOfService.id,
        effectiveFrom: effectiveFrom,
        effectiveTo: effectiveTo
    }
    const schema = yup.object({
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.practitionerLocation) {
                    toast.success("Line of Service for Provider Location updated")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"Update Line of Service"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
        >
            <HorizontalFormSelect options={linesOfServices} controlId={"lineOfServiceId"} label={"Line of Service"} readMode={true} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
export default EditPractitionerLocationLineOfServiceModal