import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {addEditAndDeleteCells, DateCell} from "../../../components/common/advance-table/CustomCells";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {useUser} from "../../../hooks/useUser";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import NewLocationVersionModal from "./NewLocationVersionModal";
import EditLocationVersionModal from "./EditLocationVersionModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";
import {useTableModals} from "../../../hooks/useTableModals";

const cols = [
    {accessor: 'name', Header: 'Name'},
    {accessor: 'description', Header: "Description"},
    {accessor: 'numberOfBeds', Header: "Number of Beds"},
    {accessor: 'adaAccessible', Header:"ADA Accessible"},
    {accessor: 'effectiveFrom', Header: 'Effective From', Cell: DateCell},
    {accessor: 'effectiveTo', Header: 'Effective To', Cell: DateCell}
]
const LocationVersions = ({versions, locationId}) => {
    const mutation = graphql`
        mutation LocationVersionsDeleteMutation($id: ID!) {
            deleteNode(input: {id: $id}) {
                owner {
                    ... on Location {
                        id
                        versions {
                            id
                        }
                        applicableVersion {
                            name
                            id
                        }
                    }
                }
            }
        }`
    const data = useFragment(graphql`
    fragment LocationVersions_Version on LocationVersion @relay(plural:true){
        id
        name
        description
        numberOfBeds
        adaAccessible
        canProvideMedicationsToTreatOpioidDisorders
        effectiveFrom
        effectiveTo
    }`, versions);
    const {hasPermission} = useUser();
    const {selected, columns, newModal, editModal, deleteModal} = useTableModals(cols, "Locations.Update", "Locations.Update");
    return (
        <>
            <AdvanceTableWrapper columns={columns}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
             <Card className={"mb-3"}>
                 <Card.Header>
                     <CardTableHeader onNewClick={newModal.onClick}
                                      title={"Versions"}
                                      table
                                      showNew={hasPermission("Locations.Update")} />
                 </Card.Header>
                 <Card.Body className={"p-0"}>
                     <AdvanceTable table
                                   headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                   rowClassName={"align-middle white-space-nowrap"}
                                   tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                     />
                 </Card.Body>
                 <Card.Footer>
                     <AdvanceTablePagination table />
                 </Card.Footer>
             </Card>
            </AdvanceTableWrapper>
            <NewLocationVersionModal showModal={newModal.showModal} 
                                     onClose={newModal.onClose}
                                     locationId={locationId}
            />
            {selected && <EditLocationVersionModal showModal={editModal.showModal} 
                                      onClose={editModal.onClose}
                                      selectedLocationVersion={selected}
            />}
            {selected && <DeleteModal mutation={mutation}
                         onClose={deleteModal.onClose}
                         thingToDelete={"Version"} 
                         showModal={deleteModal.showModal}
                         id={selected?.id}
            >
                    <p>Delete this Location Version: {selected?.name}?</p>
            </DeleteModal>}
        </>
    )
}
LocationVersions.propTypes = {
    versions: PropTypes.array.isRequired,
    locationId: PropTypes.string.isRequired
}
export default LocationVersions