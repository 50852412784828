/**
 * @generated SignedSource<<41257a522cf768c47e8706331ed347bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acceptingNewPatients"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practitionerLocationId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providesHomeVisits"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providesOfficeVisits"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providesTelehealth"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showInDirectory"
},
v8 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "acceptingNewPatients",
        "variableName": "acceptingNewPatients"
      },
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "practitionerLocationId",
        "variableName": "practitionerLocationId"
      },
      {
        "kind": "Variable",
        "name": "providesHomeVisits",
        "variableName": "providesHomeVisits"
      },
      {
        "kind": "Variable",
        "name": "providesOfficeVisits",
        "variableName": "providesOfficeVisits"
      },
      {
        "kind": "Variable",
        "name": "providesTelehealth",
        "variableName": "providesTelehealth"
      },
      {
        "kind": "Variable",
        "name": "showInDirectory",
        "variableName": "showInDirectory"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPractitionerLocationVersionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerLocationMutationGroup",
        "kind": "LinkedField",
        "name": "practitionerLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "PractitionerLocationVersionModifyPayload",
            "kind": "LinkedField",
            "name": "createVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLocationVersion",
                "kind": "LinkedField",
                "name": "practitionerLocationVersion",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PractitionerLocation",
                    "kind": "LinkedField",
                    "name": "practitionerLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLocationVersion",
                        "kind": "LinkedField",
                        "name": "versions",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PractitionerLocationVersions_Versions"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v7/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewPractitionerLocationVersionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerLocationMutationGroup",
        "kind": "LinkedField",
        "name": "practitionerLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "PractitionerLocationVersionModifyPayload",
            "kind": "LinkedField",
            "name": "createVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLocationVersion",
                "kind": "LinkedField",
                "name": "practitionerLocationVersion",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PractitionerLocation",
                    "kind": "LinkedField",
                    "name": "practitionerLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLocationVersion",
                        "kind": "LinkedField",
                        "name": "versions",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mondayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tuesdayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "wednesdayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thursdayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fridayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "saturdayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sundayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "acceptingNewPatients",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "facilityWheelchairAccessible",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requiresReferral",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "appointmentWaitTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showInDirectory",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "providesHomeVisits",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "providesOfficeVisits",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "providesTelehealth",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a24dcd351d49180d58ee165e3964eec",
    "id": null,
    "metadata": {},
    "name": "NewPractitionerLocationVersionModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewPractitionerLocationVersionModalMutation(\n  $practitionerLocationId: ID!\n  $showInDirectory: Boolean!\n  $acceptingNewPatients: YesNoUnknown!\n  $providesTelehealth: YesNoUnknown!\n  $providesOfficeVisits: YesNoUnknown!\n  $providesHomeVisits: YesNoUnknown!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  practitionerLocation {\n    createVersion(input: {practitionerLocationId: $practitionerLocationId, showInDirectory: $showInDirectory, acceptingNewPatients: $acceptingNewPatients, providesTelehealth: $providesTelehealth, providesOfficeVisits: $providesOfficeVisits, providesHomeVisits: $providesHomeVisits, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      practitionerLocationVersion {\n        id\n        practitionerLocation {\n          versions {\n            ...PractitionerLocationVersions_Versions\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PractitionerLocationVersions_Versions on PractitionerLocationVersion {\n  id\n  mondayHours\n  tuesdayHours\n  wednesdayHours\n  thursdayHours\n  fridayHours\n  saturdayHours\n  sundayHours\n  acceptingNewPatients\n  facilityWheelchairAccessible\n  requiresReferral\n  appointmentWaitTime\n  effectiveFrom\n  effectiveTo\n  showInDirectory\n  providesHomeVisits\n  providesOfficeVisits\n  providesTelehealth\n}\n"
  }
};
})();

node.hash = "ffc72fdb84a2f3d45957ef83288b92c8";

export default node;
