import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import dayjs from "dayjs";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import FormModal from "../../../components/common/modals/FormModal";
import {createOptions} from "../../../helpers/utils";
import {allTaxonomiesDropdown} from "../../../relay/Fragments";
const EditPractitionerLocationTaxonomyModal = ({showModal, onClose, selectedTaxonomy, allTaxonomies}) => {
    const mutation = graphql`
    mutation EditPractitionerLocationTaxonomyModalMutation($practitionerLocationTaxonomyId: ID!, $seq: Int!,
    $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        practitionerLocation {
            modifyTaxonomy(input: {practitionerLocationTaxonomyId: $practitionerLocationTaxonomyId, seq: $seq, 
                effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                practitionerLocationTaxonomy {
                    id
                    practitionerLocation {
                        taxonomies {
                            ...PractitionerLocationTaxonomies_Taxonomy
                        }}}}}}`
    const taxonomies = createOptions(useFragment(allTaxonomiesDropdown, allTaxonomies), "id", "applicableVersion.displayName");
    const [commitMutation, isInFlight] = useMutation(mutation)
    const effectiveFrom = selectedTaxonomy?.effectiveFrom ? dayjs(selectedTaxonomy.effectiveFrom).toDate() : '';
    const effectiveTo = selectedTaxonomy?.effectiveTo ? dayjs(selectedTaxonomy.effectiveTo).toDate() : '';
    const defaultValues = {
        practitionerLocationTaxonomyId: selectedTaxonomy?.id,
        taxonomyId: selectedTaxonomy.taxonomy.id,
        seq: selectedTaxonomy?.seq,
        effectiveFrom: effectiveFrom,
        effectiveTo: effectiveTo
    };
    const schema = yup.object({
        seq: yup.number().required("Seq is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    })
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.practitionerLocation) {
                    toast.success("Taxonomy for Provider Location updated")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"Update Taxonomy"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
        >
            <HorizontalFormSelect options={taxonomies} controlId={"taxonomyId"} label={"Taxonomy"} readMode={true} />
            <HorizontalFormInput controlId={"seq"} label={"Seq"} type={"number"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
export default EditPractitionerLocationTaxonomyModal