import React from 'react';
import PropTypes from 'prop-types';
import {useLoaderData} from "react-router-dom";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import {Col, Row} from "react-bootstrap";
import LocationInfo from "./LocationInfo";
import LocationVersions from "./versions/LocationVersions";
import DeletePanel from "../../components/common/repeated-page-panels/DeletePanel";
import {useUser} from "../../hooks/useUser";
import LocationContactPoints from "./contactPoints/LocationContactPoints";

const LocationDetails = (props) => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
    query LocationDetailsQuery($id: ID!) {
        node(id: $id) {
            id
            ... on Location {
                applicableVersion {
                    name
                }
                ...LocationInfo_Location
                versions {
                    ...LocationVersions_Version
                }
                contactPoints {
                    ...LocationContactPoints_ContactPoints
                }
            }
        }
        allLegalContracts {
            ...ContractFragments_AllContractsDropdown
        }
        allCounties {
            ...Fragments_AllCountiesDropdown
        }
        allContactPointSystemCodes {
            ...Fragments_AllContactPointSystemCodesDropdown
        }
        allContactPointUseCodes {
            ...Fragments_AllContactPointUseCodesDropdown
        }
    }`, loader.locationDetailsQuery);
    const deleteMutation = graphql`
    mutation LocationDetailsDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            id
        }}`;
    const {hasPermission} = useUser();
    return (
        <>
            <DetailsPageHeader iconText={"Location"} iconType={"map-pin"} title={data.node.applicableVersion.name} />
            <Row>
                <Col>
                    <LocationInfo location={data.node} contracts={data.allLegalContracts} counties={data.allCounties} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <LocationVersions versions={data.node.versions} locationId={data.node.id} />
                </Col>
                {hasPermission("LocationContactPoints.Read") && <Col>
                    <LocationContactPoints 
                        locationId={data.node.id}
                        contactPoints={data.node.contactPoints}
                        contactPointSystemCodes={data.allContactPointSystemCodes}
                        contactPointUseCodes={data.allContactPointUseCodes}
                    />
                </Col>
                    }
            </Row>
            {hasPermission("Locations.Delete") &&
                <DeletePanel mutation={deleteMutation} redirectUrl={"/locations"} thingToDelete={"Location"} id={data.node.id}>
                    In order to delete a Location, there must be only 1 Location Version and no other related data
                </DeletePanel>}
        </>
    )
}
export default LocationDetails