import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {useUser} from "../../../hooks/useUser";
import {BooleanCell, DateCell} from "../../../components/common/advance-table/CustomCells";
import {useTableModals} from "../../../hooks/useTableModals";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import NewPractitionerLocationVersionModal from "./NewPractitionerLocationVersionModal";
import EditPractitionerLocationVersionModal from "./EditPractitionerLocationVersionModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";

const cols = [
    {accessor: 'showInDirectory', Header: 'Show in Directory', Cell: BooleanCell},
    {accessor: 'acceptingNewPatients', Header: 'Accepting New Patients'},
    {accessor: 'requiresReferral', Header: 'Requires Referral'},
    {accessor: 'effectiveFrom', Header: 'Effective From', Cell: DateCell},
    {accessor: 'effectiveTo', Header: 'Effective To', Cell: DateCell}
]
const PractitionerLocationVersions = ({versions, practitionerLocationId}) => {
    const deleteMutation = graphql`
    mutation PractitionerLocationVersionsDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on PractitionerLocation {
                    id
                    versions {
                        id
                    }}}}}`
    const data = useFragment(graphql`
        fragment PractitionerLocationVersions_Versions on PractitionerLocationVersion @relay(plural: true) {
            id
            mondayHours
            tuesdayHours
            wednesdayHours
            thursdayHours
            fridayHours
            saturdayHours
            sundayHours
            acceptingNewPatients
            facilityWheelchairAccessible
            requiresReferral
            appointmentWaitTime
            effectiveFrom
            effectiveTo
            showInDirectory
            providesHomeVisits
            providesOfficeVisits
            providesTelehealth
        }
    `, versions)
    const {hasPermission} = useUser();
    const {selected, columns, newModal, editModal, deleteModal} = useTableModals(cols, "ProviderLocations.Update", "ProviderLocations.Update");
    return (
        <>
            <AdvanceTableWrapper columns={columns}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={newModal.onClick}
                                         title={"Versions"}
                                         table
                                         showNew={hasPermission("ProviderLocations.Update")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewPractitionerLocationVersionModal showModal={newModal.showModal}
                                                 onClose={newModal.onClose}
                                                 practitionerLocationId={practitionerLocationId}
            />
            {selected && <EditPractitionerLocationVersionModal selectedVersion={selected} 
                                                               showModal={editModal.showModal} 
                                                               onClose={editModal.onClose} 
            />}
            {selected && <DeleteModal mutation={deleteMutation}
                                      onClose={deleteModal.onClose}
                                      thingToDelete={"Version"}
                                      showModal={deleteModal.showModal}
                                      id={selected.id}
            >
                <p>This cannot be undone.</p>
            </DeleteModal>}
        </>
    )
}
export default PractitionerLocationVersions