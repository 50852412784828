/**
 * @generated SignedSource<<29a7eee8e3803988a1bbe6b40fb89868>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practitionerLocationLineOfServiceId"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "practitionerLocationLineOfServiceId",
        "variableName": "practitionerLocationLineOfServiceId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPractitionerLocationLineOfServiceModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerLocationMutationGroup",
        "kind": "LinkedField",
        "name": "practitionerLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PractitionerLocationLineOfServiceModifyPayload",
            "kind": "LinkedField",
            "name": "modifyLineOFService",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLocationLineOfService",
                "kind": "LinkedField",
                "name": "practitionerLocationLineOfService",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PractitionerLocation",
                    "kind": "LinkedField",
                    "name": "practitionerLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLocationLineOfService",
                        "kind": "LinkedField",
                        "name": "linesOfService",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PractitionerLocationLineOfService"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPractitionerLocationLineOfServiceModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerLocationMutationGroup",
        "kind": "LinkedField",
        "name": "practitionerLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PractitionerLocationLineOfServiceModifyPayload",
            "kind": "LinkedField",
            "name": "modifyLineOFService",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLocationLineOfService",
                "kind": "LinkedField",
                "name": "practitionerLocationLineOfService",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PractitionerLocation",
                    "kind": "LinkedField",
                    "name": "practitionerLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLocationLineOfService",
                        "kind": "LinkedField",
                        "name": "linesOfService",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LegalContractLineOfService",
                            "kind": "LinkedField",
                            "name": "lineOfService",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LineOfService",
                                "kind": "LinkedField",
                                "name": "lineOfService",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e2f41d7260a3c5491bfe76ae0433ac0",
    "id": null,
    "metadata": {},
    "name": "EditPractitionerLocationLineOfServiceModalMutation",
    "operationKind": "mutation",
    "text": "mutation EditPractitionerLocationLineOfServiceModalMutation(\n  $practitionerLocationLineOfServiceId: ID!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  practitionerLocation {\n    modifyLineOFService(input: {practitionerLocationLineOfServiceId: $practitionerLocationLineOfServiceId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      practitionerLocationLineOfService {\n        id\n        practitionerLocation {\n          linesOfService {\n            ...PractitionerLocationLineOfService\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PractitionerLocationLineOfService on PractitionerLocationLineOfService {\n  id\n  lineOfService {\n    id\n    lineOfService {\n      name\n      id\n    }\n  }\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "8db9ce9b37707cd7f7baabf601d4d475";

export default node;
