import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {addEditAndDeleteCells, BooleanCell, DateCell} from "../../../components/common/advance-table/CustomCells";
import {useUser} from "../../../hooks/useUser";
import NewPractitionerVersionModal from "./NewPractitionerVersionModal";
import EditPractitionerVersionModal from "./EditPractitionerVersionModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";

const columns = [
    {accessor: 'firstName', Header: 'First Name'},
    {accessor: 'middleInitial', Header: 'Middle Initial'},
    {accessor: 'lastName', Header: 'Last Name'},
    {accessor: 'nameSuffix', Header: 'Name Suffix'},
    {accessor: 'nickName', Header: 'Nickname'},
    {accessor: 'qualification', Header: 'Qualification'},
    {accessor: 'allowedToBePcp', Header: 'Allowed to be PCP', Cell: BooleanCell},
    {accessor: 'dateOfBirth', Header: 'Date of Birth', Cell: DateCell},
    {accessor: 'gender.name', Header: 'Gender'},
    {accessor: 'professionalDesignation.name', Header: 'Professional Designation'},
    {accessor: 'effectiveFrom', Header: "Effective From", Cell: DateCell},
    {accessor:'effectiveTo', Header:'Effective To', Cell: DateCell}
]
const PractitionerVersions = ({versions, allGenders, allProfessionalDesignations, allEthnicities, practitionerId}) => {
    const deleteMutation = graphql`
        mutation PractitionerVersionsDeleteMutation($id: ID!) {
            deleteNode(input: {id: $id}) {
                owner {
                    ... on Practitioner {
                        id
                        versions {
                            id
                        }
                        applicableVersion {
                            id
                            firstName
                            lastName
                            middleInitial
                            qualification
                            nameSuffix
                            nickName
                        }}}}}`
    const data = useFragment(graphql`
        fragment PractitionerVersions_Versions on PractitionerVersion @relay(plural: true) {
            id
            firstName
            lastName
            middleInitial
            nickName,
            dateOfBirth,
            nameSuffix
            qualification
            allowedToBePcp,
            willingToSeePeopleWithLimitedEnglish
            willingToSeeMinorities
            willingToSeePeopleWithDisabilities
            willingToSeePeopleWithDiverseGenderIdentities
            willingToSeePeopleWithDiverseSexualOrientations
            willingToSeePeopleLivingInRuralAreas
            willingToSeePeopleAdverselyAffectedByPovertyOrInequality
            credentialingDelegated
            effectiveFrom
            effectiveTo
            gender {
                name
                id
            }
            ethnicity {
                name
                id
            }
            professionalDesignation {
                name
                id
            }
        }
    `, versions);
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const handleEditClick = (data) => {
        setSelectedVersion(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedVersion(data);
        setShowDeleteModal(true);
    }
    const cols = useMemo(() => {
        const canEdit = hasPermission("Providers.Update");
        const canDelete = hasPermission("Providers.Delete");
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick)
    }, [hasPermission])
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Versions"}
                                         table
                                         showNew={hasPermission("Providers.Update")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewPractitionerVersionModal showModal={showNewModal}
                                         onClose={() => {setShowNewModal(false)}}
                                         genders={allGenders}
                                         ethnicities={allEthnicities}
                                         professionalDesignations={allProfessionalDesignations}
                                         practitionerId={practitionerId}
            />
            {selectedVersion && <EditPractitionerVersionModal showModal={showEditModal}
                                                              onClose={() => {setShowEditModal(false)}}
                                                              selectedVersion={selectedVersion}
                                                              genders={allGenders}
                                                              ethnicities={allEthnicities}
                                                              professionalDesignations={allProfessionalDesignations}
            />}
            {selectedVersion && <DeleteModal mutation={deleteMutation}
                                             onClose={() => {setShowDeleteModal(false)}}
                                             thingToDelete={"Version"}
                                             showModal={showDeleteModal}
                                             id={selectedVersion.id}
            >
                <p>This action cannot be undone</p>
            </DeleteModal>}
        </>
    )
}
PractitionerVersions.propTypes = {
    versions:PropTypes.array.isRequired,
    allGenders:PropTypes.array.isRequired,
    allProfessionalDesignations:PropTypes.array.isRequired,
    allEthnicities: PropTypes.array.isRequired,
    practitionerId: PropTypes.string.isRequired
}
export default PractitionerVersions