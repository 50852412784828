import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useFragment, useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {FormProvider, useForm} from "react-hook-form";
import {Card, Col, Form, Row, Tab} from "react-bootstrap";
import TabNav from "./components/TabNav";
import DetailsTab from "./DetailsTab";
import BillingGroupTab from "./BillingGroups/BillingGroupTab";
import PractitionerTab from "./Practitioners/PractitionerTab";
import LocationTab from "./Locations/LocationTab";
import PractitionerLocationTab from "./PractitionerLocations/PractitionerLocationTab";
import IconButton from "../../components/common/IconButton";
import {toast} from "react-toastify";

const InProgressRosterLoad = ({rosterLoad}) => {
    const data = useFragment(graphql`
    fragment InProgressRosterLoadFragment on RosterLoad {
        id
        status
        fileName
        downloadUrl
        createdOn
        createdBy {
            userName
        }
        legalContractLinesOfService {
            linesOfService {
                lineOfService {
                    name
                }
                contract {
                    name
                }
            }
        }
        addedBillingGroups {
            id
            status
            keys {
                id
                displayOrder
                proposedValue
                propertyName
                mask {
                    type
                    value
                }
            }
            properties {
                ...usePropertyFieldFragment @relay(mask: false)
            }
        }
        changedBillingGroups {
            id
            status
            keys {
                id
                displayOrder
                proposedValue
                propertyName
                mask {
                    type
                    value
                }
            }
            properties {
                ...usePropertyFieldFragment @relay(mask: false)
            }
        }
        addedLocations {
            id
            status
            keys {
                id
                displayOrder
                proposedValue
                propertyName
                mask {
                    type
                    value
                }
            }
            properties {
                ...usePropertyFieldFragment @relay(mask: false)
            }
        }
        changedLocations {
            id
            status
            keys {
                id
                displayOrder
                proposedValue
                propertyName
                mask {
                    type
                    value
                }
            }
            properties {
                ...usePropertyFieldFragment @relay(mask: false)
            }
        }
        addedPractitioners {
            id
            status
            keys {
                id
                displayOrder
                proposedValue
                propertyName
                mask {
                    type
                    value
                }
            }
            properties {
                ...usePropertyFieldFragment @relay(mask: false)
            }
        }
        changedPractitioners {
            id
            status
            keys {
                id
                displayOrder
                proposedValue
                propertyName
                mask {
                    type
                    value
                }
            }
            properties {
                ...usePropertyFieldFragment @relay(mask: false)
            }
        }
        addedPractitionerLocations {
            id
            status
            keys {
                id
                displayOrder
                proposedValue
                propertyName
                mask {
                    type
                    value
                }
            }
            properties {
                ...usePropertyFieldFragment @relay(mask: false)
            }
            parentLocation {
                id
            }
            parentPractitioner {
                id
            }
        }
        changedPractitionerLocations {
            id
            status
            keys {
                id
                displayOrder
                proposedValue
                propertyName
                mask {
                    type
                    value
                }
            }
            properties {
                ...usePropertyFieldFragment @relay(mask: false)
            }
            parentLocation {
                id
            }
            parentPractitioner {
                id
            }
        }
    }`, rosterLoad);
    const commit = graphql`
        mutation InProgressRosterLoadCommitMutation($id: ID!) {
            rosterLoad {
                commitRosterLoad(input: {rosterLoadId: $id}) {
                    rosterLoad {
                        id
                        fileName
                        status
                    }
                }
            }
        }`
    const methods = useForm({defaultValues:{...data}});
    const [commitMutation, isInFlight] = useMutation(commit);
    
    const practitionersByKey = useMemo(() => {
        let y = new Map();
        data.addedPractitioners.concat(data.changedPractitioners).forEach((x) => {
            y.set(x.id, x);
        })
        return y;
    }, [data.addedPractitioners, data.changedPractitioners])
    const locationsByKey = useMemo(() => {
        let y = new Map();
        data.addedLocations.concat(data.changedLocations).forEach((x) => {
            y.set(x.id, x);
        })
        return y;
    }, [data.addedLocations, data.changedLocations]);

    const validateComponent = (addedName, changedName) => {
        const [added, changed] = methods.getValues([addedName, changedName]);
        const undecidedCount = added.filter(x => x.status === "Undecided").length + changed.filter(x => x.status === "Undecided").length
        if(undecidedCount > 0) {
            methods.setError(addedName, {type: 'custom'});
            return false;
        }
        return true;
    }

    const handleCommit = async () => {
        const billingGroupsValid = validateComponent("addedBillingGroups", "changedBillingGroups");
        const locationsValid = validateComponent("addedLocations", "changedLocations")
        const practitionersValid = validateComponent("addedPractitioners", "changedPractitioners");
        const practitionerLocationsValid = validateComponent("addedPractitionerLocations", "changedPractitionerLocations");
        if(billingGroupsValid && locationsValid && practitionersValid && practitionerLocationsValid) {
            commitMutation({
                variables: {
                    id: data.id
                }, onCompleted: () => {
                    toast.success("Roster Load applied.");
                }
            })
        }
    }
    
    return (
        <FormProvider {...methods}>
            <Form noValidate autoComplete={"off"}>
                <Tab.Container id={"rosterLoad"} defaultActiveKey={"rosterLoadDetails"}>
                    <TabNav rosterLoad={data.node}/>
                    <Tab.Content>
                        <Tab.Pane eventKey={"rosterLoadDetails"}>
                            <DetailsTab fileName={data.fileName} downloadUrl={data.downloadUrl}
                                        linesOfService={data.legalContractLinesOfService.map(l => l.linesOfService)}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"billingGroups"}>
                            <BillingGroupTab/>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"providers"}>
                            <PractitionerTab/>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"locations"}>
                            <LocationTab/>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"providerLocations"}>
                            <PractitionerLocationTab practitionersByKey={practitionersByKey} locationsByKey={locationsByKey} />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                <Card className={"mb-2"}>
                    <Card.Body>
                        <Row>
                            <Col>
                                <IconButton title={"apply roster"} type={"button"} onClick={handleCommit}
                                                icon={"check"}>
                                    {isInFlight ? "Applying Roster..." : "Apply Roster"}
                                </IconButton>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
        </FormProvider>
    )
}
InProgressRosterLoad.propTypes = {}
export default InProgressRosterLoad