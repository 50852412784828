import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import dayjs from "dayjs";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import HorizontalFormCheck from "../../../components/common/horizontal-form-inputs/HorizontalFormCheck";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import {yesNoUnknownOptions} from "../../../helpers/utils";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
const EditPractitionerLocationVersionModal = ({selectedVersion, showModal, onClose}) => {
    const mutation = graphql`
    mutation EditPractitionerLocationVersionModalMutation($practitionerLocationVersionId: ID!, $showInDirectory: Boolean!,
    $monHours: StringInput, $tuesHours: StringInput, $wedHours: StringInput, $thursHours: StringInput, $friHours: StringInput,
    $satHours: StringInput, $sunHours: StringInput, $appointmentWaitTime: StringInput, $requiresReferral: YesNoUnknown, $acceptingNewPatients: YesNoUnknown, 
        $wheelChairAccessible: YesNoUnknown, $providesTelehealth: YesNoUnknown, $providesHomeVisits: YesNoUnknown, 
        $providesOfficeVisits: YesNoUnknown, $effectiveFrom: DateOnly, $effectiveTo: DateOnly) {
        practitionerLocation {
            modifyVersion(input: {practitionerLocationVersionId: $practitionerLocationVersionId, showInDirectory: $showInDirectory,
            mondayHours: $monHours, tuesdayHours: $tuesHours, wednesdayHours: $wedHours, thursdayHours: $thursHours, fridayHours: $friHours,
            saturdayHours: $satHours, sundayHours: $sunHours, requiresReferral: $requiresReferral, acceptingNewPatients: $acceptingNewPatients,
            appointmentWaitTime: $appointmentWaitTime, facilityWheelchairAccessible: $wheelChairAccessible, providesTelehealth: $providesTelehealth,
            providesHomeVisits: $providesHomeVisits, providesOfficeVisits: $providesOfficeVisits, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                practitionerLocationVersion {
                    id
                    practitionerLocation {
                        versions {
                            ...PractitionerLocationVersions_Versions
                        }
                    }
                }
            }
        }
    }`
    const [commitMutation, isInFlight] = useMutation(mutation);
    const effectiveFrom = selectedVersion?.effectiveFrom ? dayjs(selectedVersion.effectiveFrom).toDate() : '';
    const effectiveTo = selectedVersion?.effectiveTo ? dayjs(selectedVersion.effectiveTo).toDate() : '';
    const defaultValues = {
        practitionerLocationVersionId: selectedVersion.id,
        showInDirectory: selectedVersion.showInDirectory,
        acceptingNewPatients: selectedVersion.acceptingNewPatients,
        monHours: selectedVersion.mondayHours,
        tuesHours: selectedVersion.tuesdayHours,
        wedHours: selectedVersion.wednesdayHours,
        thursHours: selectedVersion.thursdayHours,
        friHours: selectedVersion.fridayHours,
        satHours:selectedVersion.saturdayHours,
        sunHours: selectedVersion.sundayHours,
        requiresReferral: selectedVersion.requiresReferral,
        wheelChairAccessible: selectedVersion.facilityWheelchairAccessible,
        providesTelehealth: selectedVersion.providesTelehealth,
        providesOfficeVisits: selectedVersion.providesOfficeVisits,
        providesHomeVisits: selectedVersion.providesHomeVisits,
        appointmentWaitTime: selectedVersion.appointmentWaitTime,
        effectiveFrom: effectiveFrom,
        effectiveTo: effectiveTo
    }
    const schema = yup.object({
        effectiveFrom:  yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    })
    const methods = useForm({values: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.practitionerLocation) {
                    toast.success("Version for Provider Location updated")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                    onClose={onClose}
                    onSubmit={handleSubmit}
                   title={"Update Version"}
                   methods={methods}
                   isInFlight={isInFlight}
        >
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"acceptingNewPatients"} label={"Accepting New Patients?"} />
            <HorizontalFormCheck controlId={"showInDirectory"} label={"Show in Directory?"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"providesTelehealth"} label={"Provides Telehealth?"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"providesHomeVisits"} label={"Provides Home Visits?"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"providesOfficeVisits"} label={"Provides Office Visits?"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"wheelChairAccessible"} label={"Facility Wheelchair Accessible?"} />
            <HorizontalFormInput controlId={"appointmentWaitTime"} label={"Appointment Wait Time"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"requiresReferral"} label={"Requires Referral?"} />
            <HorizontalFormInput controlId={"monHours"} label={"Monday Hours"} />
            <HorizontalFormInput controlId={"tuesHours"} label={"Tuesday Hours"} />
            <HorizontalFormInput controlId={"wedHours"} label={"Wednesday Hours"} />
            <HorizontalFormInput controlId={"thursHours"} label={"Thursday Hours"} />
            <HorizontalFormInput controlId={"friHours"} label={"Friday Hours"} />
            <HorizontalFormInput controlId={"satHours"} label={"Saturday Hours"} />
            <HorizontalFormInput controlId={"sunHours"} label={"Sunday Hours"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
export default EditPractitionerLocationVersionModal