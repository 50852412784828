import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormCheck from "../../../components/common/horizontal-form-inputs/HorizontalFormCheck";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import {yesNoUnknownOptions} from "../../../helpers/utils";
const NewPractitionerLocationVersionModal = ({showModal, onClose, practitionerLocationId}) => {
    const mutation = graphql`
    mutation NewPractitionerLocationVersionModalMutation($practitionerLocationId: ID!, $showInDirectory: Boolean!,
        $acceptingNewPatients: YesNoUnknown!, $providesTelehealth: YesNoUnknown!, $providesOfficeVisits: YesNoUnknown!,
        $providesHomeVisits: YesNoUnknown!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        practitionerLocation {
            createVersion(input: {practitionerLocationId: $practitionerLocationId, showInDirectory: $showInDirectory, acceptingNewPatients: $acceptingNewPatients,
            providesTelehealth: $providesTelehealth, providesOfficeVisits: $providesOfficeVisits, providesHomeVisits: $providesHomeVisits,
                effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                practitionerLocationVersion {
                    id
                    practitionerLocation {
                        versions {
                            ...PractitionerLocationVersions_Versions
                        }
                    }
                }
            }
        }
    }`
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        practitionerLocationId: practitionerLocationId,
        showInDirectory: true,
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    };
    const schema = yup.object({
        showInDirectory: yup.boolean(),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    })
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
            if(data.practitionerLocation) {
                toast.success("Version for Provider Location created")
                methods.reset();
                onClose();
            }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   onSubmit={handleSubmit}
                   title={"New Version"}
                   methods={methods}
                   isInFlight={isInFlight}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"acceptingNewPatients"} label={"Accepting New Patients"} />
            <HorizontalFormCheck controlId={"showInDirectory"} label={"Show in Directory"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"providesTelehealth"} label={"Provides Telehealth"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"providesHomeVisits"} label={"Provides Home Visits"} />
            <HorizontalFormSelect options={yesNoUnknownOptions} controlId={"providesOfficeVisits"} label={"Provides Office Visits"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
export default NewPractitionerLocationVersionModal