import React from 'react';
import PropTypes from 'prop-types';
import {useFormContext} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";

const HorizontalFormCheck = ({controlId, label, readMode, validation, value, ...rest}) => {
    const {register, formState: {errors}} = useFormContext()
    return (
        <Form.Group as={Row} className={"mb-1"} controlId={controlId}>
                <Form.Label column xs={7} sm={3}>{label}</Form.Label>
                <Col>
                <Form.Check.Input type={"checkbox"}
                                      isInvalid={!!errors[controlId]}
                                      disabled={readMode}
                                      {...register(controlId, validation)}
                                        value={value}
                                      {...rest}
                />
                    {!!errors[controlId] && <Form.Control.Feedback type={"invalid"}>{errors[controlId].message}</Form.Control.Feedback>}
                </Col>
        </Form.Group>
    )
}
HorizontalFormCheck.defaultProps = {
    ...Form.Control.defaultProps,
    readMode: false
}
HorizontalFormCheck.propTypes = {
    ...Form.Control.propTypes,
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    readMode: PropTypes.bool,
    validation: PropTypes.object
}
export default HorizontalFormCheck