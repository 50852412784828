/**
 * @generated SignedSource<<b3f776652d14f737fd733e32cd0edaf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acceptingNewPatients"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appointmentWaitTime"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "friHours"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "monHours"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practitionerLocationVersionId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providesHomeVisits"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providesOfficeVisits"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providesTelehealth"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "requiresReferral"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "satHours"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showInDirectory"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sunHours"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "thursHours"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tuesHours"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wedHours"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wheelChairAccessible"
},
v18 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "acceptingNewPatients",
        "variableName": "acceptingNewPatients"
      },
      {
        "kind": "Variable",
        "name": "appointmentWaitTime",
        "variableName": "appointmentWaitTime"
      },
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "facilityWheelchairAccessible",
        "variableName": "wheelChairAccessible"
      },
      {
        "kind": "Variable",
        "name": "fridayHours",
        "variableName": "friHours"
      },
      {
        "kind": "Variable",
        "name": "mondayHours",
        "variableName": "monHours"
      },
      {
        "kind": "Variable",
        "name": "practitionerLocationVersionId",
        "variableName": "practitionerLocationVersionId"
      },
      {
        "kind": "Variable",
        "name": "providesHomeVisits",
        "variableName": "providesHomeVisits"
      },
      {
        "kind": "Variable",
        "name": "providesOfficeVisits",
        "variableName": "providesOfficeVisits"
      },
      {
        "kind": "Variable",
        "name": "providesTelehealth",
        "variableName": "providesTelehealth"
      },
      {
        "kind": "Variable",
        "name": "requiresReferral",
        "variableName": "requiresReferral"
      },
      {
        "kind": "Variable",
        "name": "saturdayHours",
        "variableName": "satHours"
      },
      {
        "kind": "Variable",
        "name": "showInDirectory",
        "variableName": "showInDirectory"
      },
      {
        "kind": "Variable",
        "name": "sundayHours",
        "variableName": "sunHours"
      },
      {
        "kind": "Variable",
        "name": "thursdayHours",
        "variableName": "thursHours"
      },
      {
        "kind": "Variable",
        "name": "tuesdayHours",
        "variableName": "tuesHours"
      },
      {
        "kind": "Variable",
        "name": "wednesdayHours",
        "variableName": "wedHours"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPractitionerLocationVersionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerLocationMutationGroup",
        "kind": "LinkedField",
        "name": "practitionerLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v18/*: any*/),
            "concreteType": "PractitionerLocationVersionModifyPayload",
            "kind": "LinkedField",
            "name": "modifyVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLocationVersion",
                "kind": "LinkedField",
                "name": "practitionerLocationVersion",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PractitionerLocation",
                    "kind": "LinkedField",
                    "name": "practitionerLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLocationVersion",
                        "kind": "LinkedField",
                        "name": "versions",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PractitionerLocationVersions_Versions"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v12/*: any*/),
      (v5/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v14/*: any*/),
      (v4/*: any*/),
      (v11/*: any*/),
      (v13/*: any*/),
      (v1/*: any*/),
      (v10/*: any*/),
      (v0/*: any*/),
      (v17/*: any*/),
      (v9/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPractitionerLocationVersionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerLocationMutationGroup",
        "kind": "LinkedField",
        "name": "practitionerLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v18/*: any*/),
            "concreteType": "PractitionerLocationVersionModifyPayload",
            "kind": "LinkedField",
            "name": "modifyVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLocationVersion",
                "kind": "LinkedField",
                "name": "practitionerLocationVersion",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PractitionerLocation",
                    "kind": "LinkedField",
                    "name": "practitionerLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLocationVersion",
                        "kind": "LinkedField",
                        "name": "versions",
                        "plural": true,
                        "selections": [
                          (v19/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mondayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tuesdayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "wednesdayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thursdayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fridayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "saturdayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sundayHours",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "acceptingNewPatients",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "facilityWheelchairAccessible",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requiresReferral",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "appointmentWaitTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showInDirectory",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "providesHomeVisits",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "providesOfficeVisits",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "providesTelehealth",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06477a6e9ff0f08003619b041f2b5944",
    "id": null,
    "metadata": {},
    "name": "EditPractitionerLocationVersionModalMutation",
    "operationKind": "mutation",
    "text": "mutation EditPractitionerLocationVersionModalMutation(\n  $practitionerLocationVersionId: ID!\n  $showInDirectory: Boolean!\n  $monHours: StringInput\n  $tuesHours: StringInput\n  $wedHours: StringInput\n  $thursHours: StringInput\n  $friHours: StringInput\n  $satHours: StringInput\n  $sunHours: StringInput\n  $appointmentWaitTime: StringInput\n  $requiresReferral: YesNoUnknown\n  $acceptingNewPatients: YesNoUnknown\n  $wheelChairAccessible: YesNoUnknown\n  $providesTelehealth: YesNoUnknown\n  $providesHomeVisits: YesNoUnknown\n  $providesOfficeVisits: YesNoUnknown\n  $effectiveFrom: DateOnly\n  $effectiveTo: DateOnly\n) {\n  practitionerLocation {\n    modifyVersion(input: {practitionerLocationVersionId: $practitionerLocationVersionId, showInDirectory: $showInDirectory, mondayHours: $monHours, tuesdayHours: $tuesHours, wednesdayHours: $wedHours, thursdayHours: $thursHours, fridayHours: $friHours, saturdayHours: $satHours, sundayHours: $sunHours, requiresReferral: $requiresReferral, acceptingNewPatients: $acceptingNewPatients, appointmentWaitTime: $appointmentWaitTime, facilityWheelchairAccessible: $wheelChairAccessible, providesTelehealth: $providesTelehealth, providesHomeVisits: $providesHomeVisits, providesOfficeVisits: $providesOfficeVisits, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      practitionerLocationVersion {\n        id\n        practitionerLocation {\n          versions {\n            ...PractitionerLocationVersions_Versions\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PractitionerLocationVersions_Versions on PractitionerLocationVersion {\n  id\n  mondayHours\n  tuesdayHours\n  wednesdayHours\n  thursdayHours\n  fridayHours\n  saturdayHours\n  sundayHours\n  acceptingNewPatients\n  facilityWheelchairAccessible\n  requiresReferral\n  appointmentWaitTime\n  effectiveFrom\n  effectiveTo\n  showInDirectory\n  providesHomeVisits\n  providesOfficeVisits\n  providesTelehealth\n}\n"
  }
};
})();

node.hash = "54bc598de26bad862ea65b2e9d680dbd";

export default node;
