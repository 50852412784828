import React from 'react';
import {useLoaderData} from "react-router-dom";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import AnalyzingRosterLoad from "./AnalyzingRosterLoad";
import InProgressRosterLoad from "./InProgressRosterLoad";
import CompleteRosterLoad from "./CompleteRosterLoad";
import FailedRosterLoad from "./FailedRosterLoad";
import DeletePanel from "../../components/common/repeated-page-panels/DeletePanel";
import {useUser} from "../../hooks/useUser";

const RosterLoadDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(
        graphql`query RosterLoadDetailsQuery($id: ID!) {
            node(id: $id) {
                ... on RosterLoad {
                    id
                    status
                    errorMessage
                    errorDetails
                    fileName
                    ...InProgressRosterLoadFragment
                }
            }
        }`
    ,loader.rosterLoadDetailsQuery);
    const {hasPermission} = useUser();
    const deleteMutation = graphql`
    mutation RosterLoadDetails_DeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            id
        }
    }`
    return (
        <>
            <DetailsPageHeader title={"Roster Load: " + data.node.fileName} iconText={"Roster Load"} iconType={"upload"} />
            {data.node.status === "Analyzing" && <AnalyzingRosterLoad />}
            {data.node.status === "Failed" && <FailedRosterLoad rosterLoad={data.node} />}
            {data.node.status === "InProgress" && <InProgressRosterLoad rosterLoad={data.node} />}
            {data.node.status === "Complete" && <CompleteRosterLoad />}
            {(data.node.status === "Failed" || data.node.status === "InProgress") && hasPermission("RosterLoads.Delete") &&
                <DeletePanel id={data.node.id}
                             mutation={deleteMutation} 
                             thingToDelete={"Roster Load"} 
                             redirectUrl={"/roster-loads"}>
                Deleting a roster load is a permanent action and will remove the file from the server.
            </DeletePanel>}
        </>
    )
}
export default RosterLoadDetails
