import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import {Link, useLoaderData} from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {practitionersData} from "../../data/practitioners/practitionersData";
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";
import {useFragment, usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {LinkCell} from "../../components/common/advance-table/CustomCells";
import {useUser} from "../../hooks/useUser";
import NewPractitionerModal from "./NewPractitionerModal";
import {allContractsDropdown} from "../contracts/ContractFragments";
import PractitionerFilters from "./PractitionerFilters";
import {createProviderFullName} from "../../helpers/utils";

const columns = [
  {
    accessor: "currentVersion.lastName",
    Header: "Name",
    headerProps: {className : "pe-1"},
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { applicableVersion, id} = rowData.row.original;
      return <LinkCell name={createProviderFullName(applicableVersion.firstName, applicableVersion.lastName, applicableVersion.middleInitial, applicableVersion.nameSuffix, applicableVersion.nickName, applicableVersion.qualification)}
                       to={"/practitioners/" + id} hasAvatar />
    }
  },
  {
    accessor: "legalContract.name",
    Header: "Contract",
    headerProps: {className: "pe-1"},
    filter: "includes",
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const {legalContract} = rowData.row.original;
      return <LinkCell name={legalContract.name} to={"contracts/" + legalContract.id} />
    }
  }
];

const Practitioners = (props) => {
  const loader = useLoaderData();
  const data = usePreloadedQuery(graphql`
    query PractitionersQuery {
      allPractitioners {
        id
        legalContract {
          id
          name
        }
        applicableVersion {
          lastName
          firstName
          middleInitial
          nameSuffix
          nickName
        }
      }
      allLegalContracts {
        ...ContractFragments_AllContractsDropdown
      }
    }
  `, loader.practitionersQuery);
  const {hasPermission} = useUser();
  const [showModal, setShowModal] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  return (
      <>
    <AdvanceTableWrapper
      columns={columns}
      data={data.allPractitioners}
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <IndexTableHeader name={"Providers"} 
                            onNewClick={() => {setShowModal(true)}}
                            table
                            showNew={hasPermission("Providers.Create")}
                            showFilters
                            onFiltersClick={() => {setOpenFilters(!openFilters)}} 
          />
        </Card.Header>
          <PractitionerFilters table
                               contracts={data.allLegalContracts}
                               open={openFilters}
          />
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter 
              table
              rowInfo
              rowCount={data.allPractitioners.length} 
              navButtons 
              rowsPerPageSelection 
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
        <NewPractitionerModal showModal={showModal}
                              onClose={() => {setShowModal(false)}}
                              contracts={data.allLegalContracts}
        />
        </>
  );
};

export default Practitioners;
