/**
 * @generated SignedSource<<efe392823afa43b9924e760cb3374d89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practitionerLocationTaxonomyId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "seq"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "practitionerLocationTaxonomyId",
        "variableName": "practitionerLocationTaxonomyId"
      },
      {
        "kind": "Variable",
        "name": "seq",
        "variableName": "seq"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPractitionerLocationTaxonomyModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerLocationMutationGroup",
        "kind": "LinkedField",
        "name": "practitionerLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PractitionerLocationTaxonomyModifyPayload",
            "kind": "LinkedField",
            "name": "modifyTaxonomy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLocationTaxonomy",
                "kind": "LinkedField",
                "name": "practitionerLocationTaxonomy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PractitionerLocation",
                    "kind": "LinkedField",
                    "name": "practitionerLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLocationTaxonomy",
                        "kind": "LinkedField",
                        "name": "taxonomies",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PractitionerLocationTaxonomies_Taxonomy"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPractitionerLocationTaxonomyModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerLocationMutationGroup",
        "kind": "LinkedField",
        "name": "practitionerLocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PractitionerLocationTaxonomyModifyPayload",
            "kind": "LinkedField",
            "name": "modifyTaxonomy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerLocationTaxonomy",
                "kind": "LinkedField",
                "name": "practitionerLocationTaxonomy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PractitionerLocation",
                    "kind": "LinkedField",
                    "name": "practitionerLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerLocationTaxonomy",
                        "kind": "LinkedField",
                        "name": "taxonomies",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "seq",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Taxonomy",
                            "kind": "LinkedField",
                            "name": "taxonomy",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TaxonomyVersion",
                                "kind": "LinkedField",
                                "name": "applicableVersion",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "displayName",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "df94038fcafe936357bfe3ac3a3f8f3a",
    "id": null,
    "metadata": {},
    "name": "EditPractitionerLocationTaxonomyModalMutation",
    "operationKind": "mutation",
    "text": "mutation EditPractitionerLocationTaxonomyModalMutation(\n  $practitionerLocationTaxonomyId: ID!\n  $seq: Int!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  practitionerLocation {\n    modifyTaxonomy(input: {practitionerLocationTaxonomyId: $practitionerLocationTaxonomyId, seq: $seq, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      practitionerLocationTaxonomy {\n        id\n        practitionerLocation {\n          taxonomies {\n            ...PractitionerLocationTaxonomies_Taxonomy\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PractitionerLocationTaxonomies_Taxonomy on PractitionerLocationTaxonomy {\n  id\n  seq\n  taxonomy {\n    id\n    applicableVersion {\n      displayName\n      id\n    }\n  }\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "725065334015c007ffb431b8ad9b9823";

export default node;
