/**
 * @generated SignedSource<<d81172fe956b2ded29a806d841677fb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acceptingNewPatients"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lineOfServiceIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locationId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practitionerId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providesHomeVisits"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providesOfficeVisits"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providesTelehealth"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showInDirectory"
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PractitionerLocationMutationGroup",
    "kind": "LinkedField",
    "name": "practitionerLocation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "acceptingNewPatients",
                "variableName": "acceptingNewPatients"
              },
              {
                "kind": "Variable",
                "name": "effectiveFrom",
                "variableName": "effectiveFrom"
              },
              {
                "kind": "Variable",
                "name": "effectiveTo",
                "variableName": "effectiveTo"
              },
              {
                "kind": "Variable",
                "name": "legalContractLineOfServiceIds",
                "variableName": "lineOfServiceIds"
              },
              {
                "kind": "Variable",
                "name": "locationId",
                "variableName": "locationId"
              },
              {
                "kind": "Variable",
                "name": "practitionerId",
                "variableName": "practitionerId"
              },
              {
                "kind": "Variable",
                "name": "providesHomeVisits",
                "variableName": "providesHomeVisits"
              },
              {
                "kind": "Variable",
                "name": "providesOfficeVisits",
                "variableName": "providesOfficeVisits"
              },
              {
                "kind": "Variable",
                "name": "providesTelehealth",
                "variableName": "providesTelehealth"
              },
              {
                "kind": "Variable",
                "name": "showInDirectory",
                "variableName": "showInDirectory"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "PractitionerLocationModifyPayload",
        "kind": "LinkedField",
        "name": "create",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PractitionerLocation",
            "kind": "LinkedField",
            "name": "practitionerLocation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPractitionerLocationModalMutation",
    "selections": (v10/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v9/*: any*/),
      (v0/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewPractitionerLocationModalMutation",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "d36f4406a9016b00b8735b393eb7f74d",
    "id": null,
    "metadata": {},
    "name": "NewPractitionerLocationModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewPractitionerLocationModalMutation(\n  $practitionerId: ID!\n  $locationId: ID!\n  $lineOfServiceIds: [ID!]!\n  $showInDirectory: Boolean!\n  $acceptingNewPatients: YesNoUnknown!\n  $providesTelehealth: YesNoUnknown!\n  $providesOfficeVisits: YesNoUnknown!\n  $providesHomeVisits: YesNoUnknown!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  practitionerLocation {\n    create(input: {practitionerId: $practitionerId, locationId: $locationId, showInDirectory: $showInDirectory, providesTelehealth: $providesTelehealth, acceptingNewPatients: $acceptingNewPatients, providesHomeVisits: $providesHomeVisits, providesOfficeVisits: $providesOfficeVisits, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo, legalContractLineOfServiceIds: $lineOfServiceIds}) {\n      practitionerLocation {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "a6f2ec5a2a56e9525ed7701feffe3704";

export default node;
