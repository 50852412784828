import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {DateCell} from "../../../components/common/advance-table/CustomCells";
import {useUser} from "../../../hooks/useUser";
import {useTableModals} from "../../../hooks/useTableModals";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import NewPractitionerLocationLineOfServiceModal from "./NewPractitionerLocationLineOfServiceModal";
import EditPractitionerLocationLineOfServiceModal from "./EditPractitionerLocationLineOfServiceModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";

const cols = [
    {accessor: 'lineOfService.lineOfService.name', Header: 'Line of Service'},
    {accessor: 'effectiveFrom', Header: "Effective From", Cell: DateCell},
    {accessor:'effectiveTo', Header:'Effective To', Cell: DateCell}
]
const PractitionerLocationLineOfService = ({practitionerLocationId, linesOfService, availableLinesOfService,}) => {
    const deleteMutation = graphql`
    mutation PractitionerLocationLineOfServiceDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on PractitionerLocation {
                    id
                    linesOfService {
                        id
                    }}}}}`
    const data = useFragment(graphql`
        fragment PractitionerLocationLineOfService on PractitionerLocationLineOfService @relay(plural: true) {
            id
            lineOfService {
                id
                lineOfService {
                    name
                }
            }
            effectiveFrom
            effectiveTo
        }
    `, linesOfService);
    const {hasPermission} = useUser();
    const {selected, columns, newModal, editModal, deleteModal} = useTableModals(cols, "ProviderLocations.Update", "ProviderLocations.Update");
    return (
        <>
            <AdvanceTableWrapper columns={columns}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={newModal.onClick}
                                         title={"Lines of Service"}
                                         table
                                         showNew={hasPermission("ProviderLocations.Update")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewPractitionerLocationLineOfServiceModal showModal={newModal.showModal}
                                                       onClose={newModal.onClose}
                                                       practitionerLocationId={practitionerLocationId}
                                                       availableLinesOfService={availableLinesOfService}
            />
            {selected && <EditPractitionerLocationLineOfServiceModal showModal={editModal.showModal}
                                                                     onClose={editModal.onClose}
                                                                     selected={selected}
                                                                     availableLinesOfService={availableLinesOfService}
            />}
            {selected && <DeleteModal mutation={deleteMutation} 
                                      onClose={deleteModal.onClose} 
                                      thingToDelete={"Provider Location's Line of Service"} 
                                      showModal={deleteModal.showModal}
                                      id={selected.id}
            >
                <p>This cannot be undone.</p>
            </DeleteModal>}
        </>
    )
}
export default PractitionerLocationLineOfService