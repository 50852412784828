import React from 'react';
import PropTypes from 'prop-types';
import FormModal from "../../../components/common/modals/FormModal";
import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const NewPractitionerVersionModal = ({showModal, onClose, practitionerId}) => {
    const mutation = graphql`
    mutation NewPractitionerVersionModalMutation($practitionerId: ID!, $firstName: String!, $lastName: String!, $middleInitial: String!,
        $nameSuffix: String!, $nickName: String!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        practitioner {
            createVersion(input: {firstName: $firstName, lastName: $lastName, middleInitial: $middleInitial, nameSuffix: $nameSuffix,
            nickName: $nickName, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo, practitionerId: $practitionerId}) {
                practitionerVersion {
                    id
                    practitioner {
                        versions {
                            ...PractitionerVersions_Versions
                        }
                        applicableVersion {
                            id
                        }}}}}}`
    const [commitMutation, isInFlight] =  useMutation(mutation);
    const defaultValues = {
        practitionerId: practitionerId,
        firstName: '',
        lastName: '',
        middleInitial: '',
        nickName: '',
        nameSuffix: '',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31),
    }
    const schema = yup.object({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        middleInitial: yup.string().max(1, "Middle Initial can only be 1 letter"),
        dateOfBirth: yup.date(),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});

    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.practitioner) {
                    toast.success("Version for Provider created")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"New Version"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormInput controlId={"firstName"} label={"First Name"} />
            <HorizontalFormInput controlId={"middleInitial"} label={"Middle Initial"} />
            <HorizontalFormInput controlId={"lastName"} label={"Last Name"} />
            <HorizontalFormInput controlId={"nameSuffix"} label={"Name Suffix"} />
            <HorizontalFormInput controlId={"nickName"} label={"Nick Name"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
NewPractitionerVersionModal.propTypes = {}
export default NewPractitionerVersionModal