import React from 'react';
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {useUser} from "../../../hooks/useUser";
import {useTableModals} from "../../../hooks/useTableModals";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import {DateCell} from "../../../components/common/advance-table/CustomCells";
import NewPractitionerLocationTaxonomyModal from "./NewPractitionerLocationTaxonomyModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";
import EditPractitionerLocationTaxonomyModal from "./EditPractitionerLocationTaxonomyModal";

const cols = [
    {accessor: 'taxonomy.applicableVersion.displayName', Header: 'Taxonomy'},
    {accessor: 'seq', Header:"Seq"},
    {accessor: 'effectiveFrom', Header: "Effective From", Cell: DateCell},
    {accessor:'effectiveTo', Header:'Effective To', Cell: DateCell}
]
const PractitionerLocationTaxonomies = ({practitionerLocationId, taxonomies, allTaxonomies}) => {
    const deleteMutation = graphql`
    mutation PractitionerLocationTaxonomiesDeleteMutation($id:ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on PractitionerLocation {
                    id
                    taxonomies {
                        id
                    }}}}}`
    const data = useFragment(graphql`
        fragment PractitionerLocationTaxonomies_Taxonomy on PractitionerLocationTaxonomy @relay(plural: true) {
            id
            seq
            taxonomy {
                id
                applicableVersion {
                    displayName
                }
            }
            effectiveFrom
            effectiveTo
        }
    `,taxonomies)
    const {hasPermission} = useUser();
    const {selected, columns, newModal, editModal, deleteModal} = useTableModals(cols, "ProviderLocations.Update", "ProviderLocations.Update");
    return (
        <>
            <AdvanceTableWrapper columns={columns}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={newModal.onClick}
                                         title={"Taxonomies"}
                                         table
                                         showNew={hasPermission("ProviderLocations.Update")} />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewPractitionerLocationTaxonomyModal showModal={newModal.showModal} 
                                                  onClose={newModal.onClose}
                                                  practitionerLocationId={practitionerLocationId}
                                                  allTaxonomies={allTaxonomies}
            />
            {selected && <EditPractitionerLocationTaxonomyModal showModal={editModal.showModal}
                                                                onClose={editModal.onClose}
                                                                selectedTaxonomy={selected}
                                                                allTaxonomies={allTaxonomies}
            />}
            {selected && <DeleteModal mutation={deleteMutation} 
                                      onClose={deleteModal.onClose} 
                                      thingToDelete={"Provider Location's Taxonomy"}
                                      showModal={deleteModal.showModal}
                                      id={selected.id}
            >
            <p>This cannot be undone.</p>
            </DeleteModal>}
        </>
    )
}
export default PractitionerLocationTaxonomies